import React from "react"
import Layout from "../components/layout-static"
import Seo from "../components/seo"


const Page = () => {
  const metadata = {
    title: "Page not found",
    heroH1: "Page not found",
    heroH2: "Let's see if we can get you somewhere more helpful",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Page not found</h2>
            <p className="mb-8">We're sorry but the page you are trying to reach does not exist.</p>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default Page
